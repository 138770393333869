import { createRouter, createWebHashHistory } from 'vue-router';
import App from './App.vue';

import { useAuthStore } from '@/store/auth'

import { useRegistrationListStore } from '@/store/registration/registrationListStore';


const routes = [
    {
        path: '/',
        name: 'app',
        component: App,
        children: [
            {
                path: '',
                name: 'dashboard',
                component: () => import('./components/Dashboard.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/registration/detail',
                name: 'registrationdetail',
                component: () => import('./components/registration/RegistrationBasicInfo.vue'),
                meta: { requiresAuth: true },
                // children: [
                //     {
                //         path: '',
                //         component: () => import('./components/menu/PersonalDemo.vue')
                //     },
                //     {
                //         path: '/registration/detail/seat',
                //         component: () => import('./components/menu/SeatDemo.vue')
                //     },
                //     {
                //         path: '/registration/detail/payment',
                //         component: () => import('./components/menu/PaymentDemo.vue')
                //     },
                //     {
                //         path: '/registration/detail/confirmation',
                //         component: () => import('./components/menu/ConfirmationDemo.vue')
                //     },
                // ],
            },
            {
                path: '/registration/list',
                name: 'registrationlist',
                component: () => import('./components/registration/RegistrationList.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/registration/multiple-room-management/:bBasicInformationId/:addRoomNumber',
                name: 'multipleRoomManagement',
                component: () => import('./components/registration/multiple-room/MultipleRoomManagement.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/schedule',
                name: 'schedule',
                component: () => import('./components/schedule/Scheduler.vue'),
                meta: { requiresAuth: true }
            },

            {
                path: '/schedule/deliver',
                name: 'deliverRoomKey',
                component: () => import('./components/schedule/DeliverRoomKey.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/availability/list',
                name: 'availabilitylist',
                component: () => import('./components/availability/RoomAvailability.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/linkage/temairazulist',
                name: 'linkagetemairazulist',
                component: () => import('./components/linkage/LinkageTemairazuList.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/report/receivableList',
                name: 'receivableList',
                component: () => import('./components/report/receivableList.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/report/cashBalanceList',
                name: 'cashBalanceList',
                component: () => import('./components/report/cashBalanceList.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/report/input-journal',
                name: 'input-journal',
                component: () => import('./components/report/input-journal.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/report/receipt-list',
                name: 'receipt-list',
                component: () => import('./components/report/receipt-list.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/report/room-sales',
                name: 'room-sales',
                component: () => import('./components/report/room-sales.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/report/sales-daily',
                name: 'sales-daily',
                component: () => import('./components/report/sales-daily.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/report/tax-report',
                name: 'tax-report',
                component: () => import('./components/report/tax-report.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/report/payment-report',
                name: 'payment-report',
                component: () => import('./components/report/payment-report.vue'),
                meta: { requiresAuth: true }
            },
            // HOTELOS_DEV-169 出力期間を指定しレジに紐づいているクーポン情報を券種別に集計し出力
            {
                path: '/report/coupon-report',
                name: 'coupon-report',
                component: () => import('./components/report/coupon-report.vue'),
                meta: { requiresAuth: true }
            },

            {
                path: '/report/stay-tax',
                name: 'stay-tax',
                component: () => import('./components/report/stay-tax.vue'),
                meta: { requiresAuth: true }
            },
            // HOTELOS_DEV-194 料金カレンダー add start
            {
                path: '/master/plan/season-calendar',
                name: 'season',
                component: () => import('./components/master/SeasonCalendar.vue'),
                meta: { requiresAuth: true }
            },
            // HOTELOS_DEV-194 料金カレンダー add end

            // HOTELOS_DEV-196 プラン検索 add start
            {
                path: '/master/plan/list',
                name: 'plan',
                component: () => import('./components/master/Plan.vue'),
                meta: { requiresAuth: true }
            },
            // HOTELOS_DEV-196 プラン検索 add end

            // HOTELOS_DEV-199 基本・料金情報登録 add start
            {
                path: '/master/plan/detail',
                name: 'planDetail',
                component: () => import('./components/master/PlanDetail.vue'),
                meta: { requiresAuth: true }
            },
            // HOTELOS_DEV-199 基本・料金情報登録 add end

            {
                path: '/linkage/temairazulistDetail',
                name: 'linkagetemairazulistDetail',
                component: () => import('./components/linkage/LinkageTemairazuListDetail.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/master/store',
                name: 'store',
                component: () => import('./components/master/Store.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/master/store/squence',
                name: 'storeSequenceSettings',
                component: () => import('./components/master/StoreSequenceSettings.vue'),
                meta: { requiresAuth: true }
            },
            // HOTELOS_DEV-213 20221018 add start
            {
                path: '/master/tax',
                name: 'tax',
                component: () => import('./components/master/TaxTab.vue'),
                meta: { requiresAuth: true }
            },
            // HOTELOS_DEV-213 20221018 add end

            // HOTELOS_DEV-211 20221019 add start
            {
                path: '/master/consumption-tax',
                name: 'consumptionTax',
                component: () => import('./components/master/ConsumptionTax.vue'),
                meta: { requiresAuth: true }
            },
            // HOTELOS_DEV-211 20221018 add end

            // HOTELOS_DEV-223 20221019 部屋タイプ start
            {
                path: '/master/room-type',
                name: 'roomtype',
                component: () => import('./components/master/RoomType.vue'),
                meta: { requiresAuth: true }
            },
            // HOTELOS_DEV-223 20221019 部屋タイプ end

            // HOTELOS_DEV-225 部屋名称（番号） add start
            {
                path: '/master/room',
                name: 'room',
                component: () => import('./components/master/Room.vue'),
                meta: { requiresAuth: true }
            },
            // HOTELOS_DEV-225  部屋名称（番号） add end

            // HOTELOS_DEV-232 20221020 add start
            {
                path: '/master/payment-account',
                name: 'paymentAccount',
                component: () => import('./components/master/PaymentAccount.vue'),
                meta: { requiresAuth: true }
            },
            // HOTELOS_DEV-232 20221020 add end

            // HOTELOS_DEV-231 20221020 add start
             {
                path: '/master/payment-method',
                name: 'paymentMethod',
                component: () => import('./components/master/PaymentMethod.vue'),
                meta: { requiresAuth: true }
            },
            // HOTELOS_DEV-231 20221020 add end

            // HOTELOS_DEV-231 20221026仕様変更 add start
            {
                path: '/master/accounting-item',
                name: 'accountingItem',
                component: () => import('./components/master/AccountingItem.vue'),
                meta: { requiresAuth: true }
            },
            // HOTELOS_DEV-231 20221026仕様変更 add end

            // HOTELOS_DEV-219 20221114 add start
            {
                path: '/master/user',
                name: 'user',
                component: () => import('./components/master/User.vue'),
                meta: { requiresAuth: true }
            },
            // HOTELOS_DEV-219 20221114 add end


            // HOTELOS_DEV-216 利用目的マスタ add start
            {
                path: '/master/purpose-group',
                name: 'purposeGroup',
                component: () => import('./components/master/PurposeGroup.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/master/purpose',
                name: 'purpose',
                component: () => import('./components/master/Purpose.vue'),
                meta: { requiresAuth: true }
            },
            // HOTELOS_DEV-216 利用目的マスタ add end

            // HOTELOS_DEV-193 プランマスタ add start
            {
                path: '/master/plan-group',
                name: 'planGroup',
                component: () => import('./components/master/PlanGroup.vue'),
                meta: { requiresAuth: true }
            },
            // HOTELOS_DEV-193 プランマスタ add end

            // HOTELOS_DEV-234 20221108仕様変更 add start
            {
                path: '/master/billing-address',
                name: 'billingAddress',
                component: () => import('./components/master/BillingAddress.vue'),
                meta: { requiresAuth: true }
            },
            // HOTELOS_DEV-234 20221108仕様変更 add end

            // HOTELOS_DEV-217 20221109仕様変更 add start
            {
                path: '/master/sales-route-group',
                name: 'salesRouteGroup',
                component: () => import('./components/master/SalesRouteGroup.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/master/sales-route',
                name: 'salesRoute',
                component: () => import('./components/master/SalesRoute.vue'),
                meta: { requiresAuth: true }
            },
            // HOTELOS_DEV-217 20221109仕様変更 add end

            // HOTELOS_DEV-205 手配マスタ add start
            {
                path: '/master/arrangement-group',
                name: 'arrangementGroup',
                component: () => import('./components/master/ArrangementGroup.vue'),
                meta: { requiresAuth: true }
            },

            {
                path: '/master/arrangement',
                name: 'arrangement',
                component: () => import('./components/master/Arrangement.vue'),
                meta: { requiresAuth: true }
            },
            // HOTELOS_DEV-205 手配マスタ add end

            // HOTELOS_DEV-209 子供の種別 20221111 add start
            {
                path: '/master/child-fee',
                name: 'childFee',
                component: () => import('./components/master/ChildFee.vue'),
                meta: { requiresAuth: true }
            },
            // HOTELOS_DEV-209 子供の種別 2022111 add end

            // HOTELOS_DEV-239 20221125 add start
            {
                path: '/master/free-memo',
                name: 'freeMemo',
                component: () => import('./components/master/FreeMemo.vue'),
                meta: { requiresAuth: true }
            },
            // HOTELOS_DEV-239  20221125 add end

            // HOTELOS_DEV-539 20230613 add start
            {
                path: '/master/rate-strategies',
                name: 'rateStrategies',
                component: () => import('./components/master/RateStrategies.vue'),
                meta: { requiresAuth: true }
            },

            {
                path: '/master/rate-restrictions',
                name: 'rateRestrictions',
                component: () => import('./components/master/RateRestrictions.vue'),
                meta: { requiresAuth: true }
            },

            {
                path: '/master/rate-search',
                name: 'rateSearch',
                component: () => import('./components/schedule/RateSearch.vue'),
                meta: { requiresAuth: true }
            },
            // HOTELOS_DEV-539  20230613 add end

            {
                path: '/admin/master/group',
                name: 'admin_group',
                component: () => import('./components/admin/master/Group.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/admin/master/company',
                name: 'admin_company',
                component: () => import('./components/admin/master/Company.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/admin/master/store',
                name: 'admin_store',
                component: () => import('./components/admin/master/Store.vue'),
                meta: { requiresAuth: true }
            },
            
            {
                path: '/master/menu-role-type',
                name: 'menuRoleType',
                component: () => import('./components/master/MenuRoleType.vue'),
                meta: { requiresAuth: true }
            },

            {
                path: '/formlayout',
                name: 'formlayout',
                component: () => import('./components/FormLayoutDemo.vue')
            },
            {
                path: '/input',
                name: 'input',
                component: () => import('./components/InputDemo.vue')
            },
            {
                path: '/floatlabel',
                name: 'floatlabel',
                component: () => import('./components/FloatLabelDemo.vue')
            },
            {
                path: '/invalidstate',
                name: 'invalidstate',
                component: () => import('./components/InvalidStateDemo.vue')
            },
            {
                path: '/button',
                name: 'button',
                component: () => import('./components/ButtonDemo.vue')
            },
            {
                path: '/table',
                name: 'table',
                component: () => import('./components/TableDemo.vue')
            },
            {
                path: '/list',
                name: 'list',
                component: () => import('./components/ListDemo.vue')
            },
            {
                path: '/tree',
                name: 'tree',
                component: () => import('./components/TreeDemo.vue')
            },
            {
                path: '/panel',
                name: 'panel',
                component: () => import('./components/PanelsDemo.vue')
            },
            {
                path: '/overlay',
                name: 'overlay',
                component: () => import('./components/OverlayDemo.vue')
            },
            {
                path: '/media',
                name: 'media',
                component: () => import('./components/MediaDemo.vue')
            },
            {
                path: '/menu',
                component: () => import('./components/MenuDemo.vue'),
                children: [
                    {
                        path: '',
                        component: () => import('./components/menu/PersonalDemo.vue')
                    },
                    {
                        path: '/menu/seat',
                        component: () => import('./components/menu/SeatDemo.vue')
                    },
                    {
                        path: '/menu/payment',
                        component: () => import('./components/menu/PaymentDemo.vue')
                    },
                    {
                        path: '/menu/confirmation',
                        component: () => import('./components/menu/ConfirmationDemo.vue')
                    },
                ],
            },
            {
                path: '/messages',
                name: 'messages',
                component: () => import('./components/MessagesDemo.vue')
            },
            {
                path: '/file',
                name: 'file',
                component: () => import('./components/FileDemo.vue')
            },
            {
                path: '/chart',
                name: 'chart',
                component: () => import('./components/ChartDemo.vue')
            },
            {
                path: '/misc',
                name: 'misc',
                component: () => import('./components/MiscDemo.vue')
            },
            {
                path: '/crud',
                name: 'crud',
                component: () => import('./pages/CrudDemo.vue')
            },
            {
                path: '/timeline',
                name: 'timeline',
                component: () => import('./pages/TimelineDemo.vue')
            },
            {
                path: '/empty',
                name: 'empty',
                component: () => import('./components/EmptyPage.vue')
            },
            {
                path: '/documentation',
                name: 'documentation',
                component: () => import('./components/Documentation.vue')
            },
            {
                path: '/blocks',
                name: 'blocks',
                component: () => import('./components/BlocksDemo.vue')
            },
            {
                path: '/icons',
                name: 'icons',
                component: () => import('./components/IconsDemo.vue')
            },
            {
                path: '/admin/master/analysis',
                name: 'analysisDetail',
                component: () => import('./components/analysis/AnalysisDetail.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/admin/master/batch',
                name: 'batch',
                children: [
                    {
                        path: '/admin/master/batch',
                        component: () => import('./components/batch/BatchChecked.vue'),
                    },
                    {
                        path: '/admin/master/batch/PHASE_02',
                        component: () => import('./components/batch/BatchStepSales.vue')
                    },
                    {
                        path: '/admin/master/batch/PHASE_04',
                        component: () => import('./components/batch/BatchStepDailyUpdate.vue')
                    },
                    {
                        path: '/admin/master/batch/PHASE_05',
                        component: () => import('./components/batch/BatchStepStatusUpdate.vue')
                    },
                    {
                        path: '/admin/master/batch/PHASE_06',
                        component: () => import('./components/batch/BatchStepReport.vue')
                    },
                    {
                        path: '/admin/master/batch/PHASE_99',
                        component: () => import('./components/batch/BatchSuccess.vue')
                    },
                ],
                component: () => import('./components/batch/Batch.vue'),
                meta: { requiresAuth: true }
            }
        ]
    },
    {
        path: '/forget',
        name: 'forget',
        component: () => import('./pages/Forget.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/login/staff',
        name: 'loginStaff',
        component: () => import('./pages/LoginStaff.vue')
    },
    {
        path: '/landing',
        name: 'landing',
        component: () => import('./pages/LandingDemo.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

// 認証制御
router.beforeEach((to, from, next) => {
    const authStore = useAuthStore();
    const rdStore = useRegistrationListStore();
    rdStore.routerFrom = from;

    if (to.matched.some(record => record.meta.requiresAuth) && !authStore.Authenticated) {
        next({ path: '/login', query: { redirect: to.fullPath } });
    } else {
        next();
    }
});

export default router;
